import React from 'react';

import Layout from '../components/Layout';

const IndexPage = () => (
  <Layout fullMenu>
    <article id="main">
      <header>
        <h2>Services</h2>
        <p>We offer a variety of services to maintain and care for your dock</p>
      </header>
      <section className="wrapper style5">
        <div className="inner">

          <section>
            <div className="table-wrapper">
              <table className="alt">
                <tbody>
                <tr>
                  <td>Build</td>
                  <td>We build new docks based on your specifications.</td>
                </tr>
                <tr>
                  <td>Relocate</td>
                  <td>We will help you remove, transport and install your existing docks.</td>
                </tr>
                <tr>
                  <td>Service</td>
                  <td>We can help keep your existing docks in working order so you can avoid any problems.</td>
                </tr>
                <tr>
                  <td>Repair</td>
                  <td>We can install new anchors or moorings and perform any necessary maintenance.</td>
                </tr>
                </tbody>
              </table>
            </div>
          </section>


        </div>
      </section>
    </article>
  </Layout>
);

export default IndexPage;
